import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import EdgeCoreMonitorComponents from "./pageComponents/EdgeCoreMonitorComponents";
import { Grid, Box } from "grommet/components";

import axios from "axios";
import { apiEndPoints } from "../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setAvailableApps,
  setInstalledApps,
  setInventory,
} from "../reduxStore/actions";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import Podstatus from "../components/Podstatus/Podstatus";
import ApplicationsResourceMonitor from "../pages/pageComponents/ApplicationsMonitorComponent";
import PodsSummary from "../components/PodsSummary/PodsSummary";

function EdgeResourceMonitor() {
  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  let curr_App_reloadValue = useSelector(
    (state) => state.setInstallAppsReloader
  );
  const [apiLoaded, setApiLoaded] = useState(false);

  let [installedAppLoaded, setInstallAppLoaded] = useState(false);
  const [displayApplications, setDisplayApplications] = useState(true);
  const handleDisplayChange = (event) => {
    setDisplayApplications(event.target.value === "installedApplications");
  };
  //Pod Summary
  const Pod_Inventorycounts = useSelector(
    (state) =>
      state.setEdgeStatus?.data?.edgeServiceMetricsSummary
        ?.edgeApplicationPodMetrics
  );

  const edgeDetails_metrics = useSelector((state) => state.setEdgeStatus.data);
  const matricsData_ = useSelector((state) => state.setData.data);
  const [serviceNamePodCount, setServiceNamePodCount] = useState(0);
  const [runningStatusCount, setRunningStatusCount] = useState(0);
  const [errorStatusCount, setErrorStatusCount] = useState(0);
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [appsrunningCount, setappsrunningCount] = useState(0);
  const [appserrorCount, setappserrorCount] = useState(0);
  const [appspendingCount, setappspendingCount] = useState(0);
  const [sudo_inventoryData, setsudo_inventoryData] = useState([]);
  const [cpuInventory, setcpuInventory] = useState(0);
  const [ramInventory, setramInventory] = useState(0);

  useEffect(() => {
    if (Pod_Inventorycounts && Pod_Inventorycounts.length > 0) {
      const filteredPods = Pod_Inventorycounts.filter(
        (app) => app.isPlatform == true
      );

      const filterApps = Pod_Inventorycounts.filter(
        (app) => app.isPlatform == false
      );
      let runningCount = 0;
      let errorCount = 0;
      let pendingCount = 0;
      let PodCount = 0;
      let appsRunning = 0;
      let appsPending = 0;
      let appsError = 0;

      filteredPods.forEach((application) => {
        PodCount += application.edgePodStatisticsList.length;

        application.edgePodStatisticsList.forEach((data) => {
          if (data.statusPod === "Running") {
            runningCount++;
          } else if (data.statusPod === "Error") {
            errorCount++;
          } else if (data.statusPod === "Pending") {
            pendingCount++;
          }
        });
      });

      filterApps.forEach((app) => {
        // console.log(app)
        // appsRunning++;
        app?.edgePodStatisticsList?.forEach((pod) => {
          if (pod.statusPod === "Running") {
            appsRunning++;
          } else if (pod.statusPod === "Pending") {
            appsPending++;
            // appsRunning--;
          } else if (pod.statusPod === "Error") {
            appsError++;
          }
        });
      });

      setServiceNamePodCount(PodCount);
      setRunningStatusCount(runningCount);
      setErrorStatusCount(errorCount);
      setPendingStatusCount(pendingCount);
      setappsrunningCount(appsRunning);
      setappspendingCount(appsPending);
      setappserrorCount(appsError);

      if (edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM != null) {
        setramInventory(
          edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeRAM +
            edgeDetails_metrics?.edgeServiceMetricsSummary?.availableRAM
        );
      }
      if (edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeCPU != null) {
        setcpuInventory(
          edgeDetails_metrics?.edgeServiceMetricsSummary?.nodeCPU
        );
      }
      if (sudo_inventoryData?.applicationDetails) {
        seInventoryData_redux(sudo_inventoryData);
      }
    }
  }, [Pod_Inventorycounts, sudo_inventoryData]);
  const seInventoryData_redux = (data) => {
    let inventoryData = data.applicationDetails;
    // console.log("data inside fuction", data);
    dispatch(
      setInventory({
        payload: {
          data: [
            // {
            //   name: "01 Node",
            // },
            {
              name: `Applications`,
              details: `${
                inventoryData.appsInUse == 0
                  ? " "
                  : `${inventoryData.appsInUse}/${inventoryData.appsAvailable} application(s) installed`
              } `,
              warningstatus: [
                { icon: "good", value: appsrunningCount },
                { icon: "medium", value: appspendingCount },
                { icon: "critical", value: appserrorCount },
              ],
              route: `/allapplications?edgeId=${edgeId}`,
              // isEnabled: `${platformType == "unknown" ? false : true}`,
            },
            {
              name: `${serviceNamePodCount} Pod(s)`,
              // name: "24 Pod(s)",
              // details: "of 50 Limit",
              warningstatus: [
                { icon: "good", value: runningStatusCount },
                { icon: "medium", value: pendingStatusCount },
                { icon: "critical", value: errorStatusCount },
              ],
            },
            {
              name: `${ramInventory?.toFixed(2)} GB RAM`,
              // details: "0f 100 Limit",
              // warningstatus: [{ icon: "medium", value: "1" }],
            },
            {
              // name: `${cpuInventory?.toFixed(2)} % CPU`,
              name: `${
                matricsData_?.[0]?.data?.[0]?.v == null ||
                matricsData_?.[0]?.data?.[0]?.v == undefined ||
                matricsData_?.[0]?.data?.[0]?.v == ""
                  ? "0"
                  : matricsData_?.[0]?.data?.[0]?.v
              } core CPU`,
            },
          ],
          isLoading: false,
        },
      })
    );
  };

  useEffect(() => {
    setApiLoaded(false);
    setInstallAppLoaded(false);
    //List of Installed Applications
    let toGetInstalledApps = apiEndPoints.FETCH_INSTALLED_APPS.replace(
      "*edgeId*",
      edgeId
    );

    let Fetch_Installedapps = {
      method: "get",
      maxBodyLength: Infinity,
      url: toGetInstalledApps,
      headers: {
        // Authorization: `Bearer ${access_token}`,
      },
    };
    axios
      .request(Fetch_Installedapps)
      .then((response) => {
        dispatch(setInstalledApps({ payload: response.data }));
        if (response.status == 200) {
          setInstallAppLoaded(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [curr_App_reloadValue]);

  useEffect(() => {
    if (installedAppLoaded) {
      setApiLoaded(true);
    }
  }, [installedAppLoaded]);
  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
          <Col flex="0 1 100%" sm={24} md={24} lg={24} xl={6}>
            <Row>
              <Col
                className="gridContainer"
                style={{ borderRadius: "10px", height: "92vh", width: "92vw" }}
              >
                <div>
                  <label htmlFor="displaySelector"></label>
                  <select
                    id="displaySelector"
                    onChange={handleDisplayChange}
                    value={
                      displayApplications
                        ? "installedApplications"
                        : "edgesCore"
                    }
                  >
                    <option value="installedApplications">
                      Application Instances
                    </option>
                    <option value="edgesCore">Edge Core Runtime</option>
                  </select>
                </div>
                {displayApplications ? (
                  <ApplicationsResourceMonitor apiLoaded={apiLoaded} />
                ) : (
                  <>
                    <PodsSummary />
                    <Podstatus headingVisible="true" />
                  </>
                )}
                {/* select above dropdown 
                call api depending on the dropdown value
                save the response in only one steVarible
                rendert the list that you get from the respobnse
                - copy that coumns               
                
                */}
              </Col>
            </Row>
          </Col>
          <Col
            flex="1 1 525px"
            className="gridContainer"
            style={{
              columnGap: "10px",
              display: "grid",
              rowGap: "10px",
            }}
          >
            <EdgeCoreMonitorComponents apiLoaded={apiLoaded} />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default EdgeResourceMonitor;
