/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaBell, FaUserCircle } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import "./Widget.css";
import { commonAuthHelper } from "../../App";
import { AppTopNaviItem } from "@abb/abb-common-ux-react";
import { envSettings } from "../../EnviornmentSettings/Settings";
import { UserMenu } from "@abb/abb.ia.components.react";
import { Alert, Breadcrumb, Button } from "antd";
import { HashRouter, Link, Route, Routes, useLocation } from "react-router-dom";
import Alledges from "../../pages/Alledges";
import AllTenantapplications from "../../pages/Alltenantapplications";
import { AiOutlineRight } from "react-icons/ai";
import { object } from "underscore";
import { Popover, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GeneralConstants } from "../../constants/GeneralConstants";
import { TiTimes } from "react-icons/ti";
import WarningIcons from "../WarningIcons/WarningIcons";
import { MdChevronRight } from "react-icons/md";
import {
  setPageNotifications,
} from "../../reduxStore/actions";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { calculateTimeElapsed, generateTime, renderCalculatedTime } from "../../constants/commonFunction";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import axios from "axios";
import TempComponent from "../TempComponent";

function Widget() {
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const dispatch = useDispatch();

  let allNotifications = { isNew: false, data: [], newData: [] };
  
  let sessionNotification = JSON.parse(
    sessionStorage.getItem("sessionNotification")
  );
  useEffect(() => {
    if(sessionNotification == null){

      sessionStorage.setItem(
        "sessionNotification",
        JSON.stringify(allNotifications)
      );
     
    }
    if(oldNotifications?.isNew==true && oldNotifications?.newData?.length>0){
      let newNotification = oldNotifications?.newData?.[oldNotifications?.newData?.length-1]
      sessionNotification.newData.push(newNotification)
      sessionStorage.setItem(
        "sessionNotification",
        JSON.stringify(sessionNotification)
      );
    }
    
    // if(allNotifications.newData.length==sessionNotification.newData.length){
    //   console.log(allNotifications.newData.length,sessionNotification.newData.length)
    //   if (oldNotifications?.isNew==true) {
    //     allNotifications = {
    //       isNew: false,
    //       data: [...sessionNotification.data, ...oldNotifications.data],
    //       newData: [...sessionNotification.newData, ...oldNotifications.newData],
    //     };
  
    //     sessionStorage.setItem(
    //       "sessionNotification",
    //       JSON.stringify(allNotifications)
    //     );
    //   }
    // }
   
  }, [oldNotifications]);

  useEffect(() => {
   
    dispatch(setPageNotifications({ payload: sessionNotification }));
  }, []);

  let new_pageNotifications = useSelector(
    (state) => state.setPageNotifications
  );
  

  const token = commonAuthHelper.getAccessToken();
  const decodedToken = token && token !== null ? jwt_decode(token) : "";
  const decodedTokenStr = JSON.stringify(decodedToken);
  const authDataToken = JSON.parse(decodedTokenStr);
  const [isinstalled, setIsInstalled] = useState(false);
  const queryParam = new URLSearchParams(window.location.search);

  const [pageNotifications, set_PageNotifications] = useState([]);
  const [sudo, setupdate] = useState(0);
  useEffect(() => {
    set_PageNotifications(new_pageNotifications);
  }, [new_pageNotifications]);
  const [currentStatus, setCurrentStatus] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [count, setCount] = useState(0);

  const [sudoNotiIncrement, setSudoNotiIncrement] = useState(0);
  const edgeId = queryParam.get("edgeId");
  const progresConstant = GeneralConstants.progresConstant;
  const AddNotification = (text, icon) => {
    let tp = oldNotifications.newData;
    tp.push({
      notiId: generateTime(),
      icon: `${icon}`,
      text: `${text}`,
      time: generateTime(),
    });
    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: tp,
        },
      })
    );
  };


  useEffect(() => {
    if (window.location.pathname === "/appDetails") {
      const queryParams = new URLSearchParams(window.location.search);
      const isAppInstalledParam = queryParams.get("isAppInstalled");
      setIsInstalled(isAppInstalledParam === "true");
    }
  }, [queryParam]);
  const userRoles =
    authDataToken && authDataToken.roles ? authDataToken.roles : [];
  let userNameToBeDisplayed = "";
  userNameToBeDisplayed =
    authDataToken && authDataToken.name ? authDataToken.name : "";
  const [showUserProfile, toggleUserProfile] = React.useState(false);

  const [visibleNotification, Visible_notificaiton] = useState(false);

  const toggleProfile = () => {
    toggleUserProfile(!showUserProfile);
  };
  const closeUserProfile = () => {
    toggleUserProfile(false);
  };
  const location = useLocation();

  if (window.location.pathname === "/edgedetails") {
    const edgeNameFromParams = queryParam.get("edgeName");
    localStorage.setItem("edgeName", edgeNameFromParams);
  }

  // let edgeName = localStorage.getItem('edgeName');
  let edgeName = useSelector(
    (state) => state?.setEdgeDetails?.data?.[0]?.detail
  );

  if (edgeName == undefined || window.location.pathname === "/appDetails") {
    edgeName = localStorage.getItem("edgeName");
  }

  let appName = "";

  if (window.location.pathname == "/appDetails") {
    const settingData = queryParam.get("settingData");
    const jsonsettingData = JSON.parse(settingData);
    appName = jsonsettingData["displayName"];
  }
  const breadcrumbNameMap = {
    // '/alledges': [{route: "", title:"All Edges"}],
    "/alltenantapps": [{ route: "", title: "Applications" }],
    "/edgedetails": [{ route: "", title: edgeName }],
    "/edgeResources": [
      { route: "/edgedetails", title: edgeName },
      { route: "", title: "Edge Resource Monitor" },
    ],
    "/allapplications": [
      { route: "/edgedetails", title: edgeName },
      {
        route: "",
        // title: "Installed Applications"
        title: "Applications",
      },
    ],
    "/appDetails": [
      { route: "/edgedetails", title: edgeName },
      {
        route: "/allapplications",
        // title: isinstalled
        //   ? "Installed Applications"
        //   : "Available Applications",
        title: "Applications",
      },
      { route: "", title: appName },
    ],
  };
  const extraBreadcrumbItems = breadcrumbNameMap[location.pathname] || [];

  let keys = Object.keys(breadcrumbNameMap);
  let saveTheRoutes = [];

  const userLogout = () => {
    let edgedetailsValue = "";
    if (envSettings.connectivityMode === "connected") {
      keys.map((route) => {
        saveTheRoutes.push({
          route,
          value: localStorage.getItem(route),
        });
      });
      let prev_edgeName = localStorage.getItem("edgeName");
      localStorage.clear();
      sessionStorage.clear();
      document.location.replace("/");
      saveTheRoutes.map((route) => {
        if (route.value == null) {
          return;
        }
        localStorage.setItem(route.route, route.value);
      });
      localStorage.setItem("edgeName", prev_edgeName);
    }
    commonAuthHelper.logOut();
  };

  useEffect(() => {
    localStorage.setItem(window.location.pathname, window.location);
  }, []);

  const setNavigation = (route) => {
    let prev_route = localStorage.getItem(route.route);
    window.location.href = prev_route;
  };
  const tenant_name = `${
    localStorage.getItem("tenant") !== null
      ? localStorage.getItem("tenant")
      : ""
  }`;
  let result = tenant_name.charAt(0).toUpperCase() + tenant_name.slice(1);
  const breadcrumbItems = [
    {
      title: <Link to="/"> {"Home"} </Link>,
      key: "Dashboard",
    },
  ].concat(extraBreadcrumbItems);

 

  const renderNotif = (stat) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div>
          <WarningIcons type={stat?.icon ? stat?.icon : stat?.data?.icon} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "15px", fontFamily: "ABBVoice" }}>
            {stat?.data?.isEdge?`${stat?.data?.edgeName}`:(<>{stat?.data?.edgeName} <MdChevronRight  style={{marginBottom:'-2px'}}/> {stat?.data?.appName}</>)} 
          </span>
          <span style={{ fontSize: "15px", fontFamily: "ABBVoice-bold" }}>
            {stat?.text}
          </span>
          <span style={{ fontSize: "15px", fontFamily: "ABBVoice" }}>
            {renderCalculatedTime(stat?.time)}
          </span>
        </div>
      </div>
    );
  };

  const renderProgressBar = (stat) => {
    return (
      <div>
        {stat.data.isComplete ? (
          <>{renderNotif(stat)}</>
        ) : (
          <TempComponent
            key={stat?.data?.edgeId}
            data={{
              isUpdate:stat?.data?.isUpdate,
              limit: stat?.data?.limit,
              successMessage: stat?.data?.successMessage,
              edgeId: stat?.data?.edgeId,
              isEdge:stat?.data?.isEdge,
              setting_type: stat?.data?.setting_type,
              settingSectionName: stat?.data?.settingSectionName,
              edgeName:stat?.data?.edgeName,
              appName:stat?.data?.appName,
              time: stat?.data?.time,
              icon: stat?.data?.icon,
              operation_type:stat?.data?.operation_type,
              modalKey: stat?.data?.modalKey,
            }}
          />
        )}
      </div>
    );
  };

  const NotificationContent = () => {
    return (
      <div
        style={{
          maxHeight: "400px",
          overflow: "auto",
        }}
        id="allnotiContainer"
      >
       
        {/* {pageNotifications.data.map((stat, key) => { */}
        {pageNotifications?.newData?.map((stat, key) => {
          return (
            <div
              key={key}
              style={{
                borderBottom: "1px solid #e2e2e2",
                padding: "20px 15px",
                width: "400px",
                background: "#fff",
              }}
            >
              <div
                style={
                  {
                    // display: "flex",
                    // gap: "20px",
                  }
                }
              >
                <div>
                  {stat?.data == undefined ? (
                    <>{renderNotif(stat)}</>
                  ) : (
                    <>{renderProgressBar(stat)}</>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {/* {pageNotifications.data.length == 0 && ( */}
        {pageNotifications?.newData?.length == 0 && (
          <div
            style={{
              borderBottom: "1px solid #e2e2e2",
              padding: "10px 10px",
              width: "350px",
              background: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "ABBVoice-medium",
                      color: "rgb(210, 210, 210)",
                    }}
                  >
                    No notifications
                  </span>
                </div>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const d = () => {
    if (!visibleNotification) {
      if (new_pageNotifications?.newData?.length != 0) {
        dispatch(
          setPageNotifications({
            payload: {
              isNew: false,
              data: [
                // ...new_pageNotifications.newData,
                // ...pageNotifications.data,
              ],
              newData: [...new_pageNotifications?.newData],
            },
          })
        );
      }
    }
  };
  const showNoti = (value) => {
    Visible_notificaiton(value);
    d();
  };

  useEffect(() => {
    // if (oldNotifications.newData.length == 1) {
    if (oldNotifications?.isNew) {
      showNoti(true);
     
    }
    // }
  }, [oldNotifications]);

 


  return (
    <>
      <div className="header">
        <div className="menu-icon" style={{ marginBottom: "-10px" }}>
          <GiHamburgerMenu />
        </div>
        <div className="logo">
          <img src="/logo.png" alt="ABB Logo" />
        </div>
        <div className="divider"></div>
        <div className="app-name">
          Edgenius Management Console
          <div className="demo">
            {/* <br /> */}
            <Route path="/alledges" element={<Alledges />} />
            <Route path="/alltenantapps" element={<AllTenantapplications />} />
            <Route path="*" element={<span>Dashboard Page</span>} />
            <Breadcrumb separator={<AiOutlineRight fontSize={"14px"} />}>
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>
                  {item.route ? (
                    <span
                      className="ant-breadcrumb-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => setNavigation(item)}
                    >
                      {item.title}
                    </span>
                  ) : (
                    item.title
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        </div>
        <div className="spacer"></div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <div className="notification-icon">
            <Popover
              content={NotificationContent}
              placement="bottomRight"
              // trigger="click"
              // onClick={() => toggle_Notification()}
              visible={visibleNotification}
            >
              <div
                style={{
                  borderBottom: visibleNotification
                    ? "2px solid #3366ff"
                    : "2px solid transparent",
                  width: "55px",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
                onClick={() => showNoti(!visibleNotification)}
                id="notinoti"
              >
                <FaBell />
                {pageNotifications?.newData?.length > 0 &&
                  pageNotifications.isNew && (
                    <div className="newNotificationContainer">
                      {pageNotifications.newData.length > 10 ? `` : ``}
                      {/* : pageNotifications.newData.length} */}
                    </div>
                  )}
              </div>
            </Popover>
          </div>
          <div>
            <UserMenu
              className="profile-icon"
              tenant={`${
                localStorage.getItem("tenant") !== null
                  ? localStorage.getItem("tenant")
                  : ""
              }`}
              username={`${userNameToBeDisplayed}`}
              showSettings={false}
              // onSettings={showSettingsMenu}
              roles={userRoles}
              instance={""}
              isOpen={showUserProfile}
              showSwitchTenant={false}
              trigger={
                <AppTopNaviItem
                  id="UserMenuItem"
                  icon="abb/user-in-circle"
                  active={showUserProfile}
                  onClick={() => toggleProfile()}
                />
              }
              position="bottom right"
              onRequestClose={() => closeUserProfile()}
              onSignOut={() => {
                userLogout();
              }}
            />
          </div>
        </div>
        {/* <div className="user-name">{userNameToBeDisplayed}</div> */}
      </div>
    </>
  );
}

export default Widget;
