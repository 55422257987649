import React, { useDebugValue, useEffect, useState } from "react";
import "./Widget.css";

import { Form, Input, Button, message, Upload } from "antd";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import {

  isUnsavedEdgeSettings,
  setPageNotifications,
} from "../../reduxStore/actions";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import { GeneralConstants, General_color } from "../../constants/GeneralConstants";


function Widget() {
  const dispatch = useDispatch();
  let detailsData = useSelector((state) => state.setEdgeDetails);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const [isSavingIdentityProvider, setIsSavingIdentityProvider] =
    useState(false);
  const AuthenticationSettingsData = useSelector(
    (state) => state.setAuthenticationSettings
  );

  const [refactored_base64File, setrefactored_Base64File] = useState("");
  const [providervalue, setProviderValue] = useState(
    AuthenticationSettingsData?.properties?.[1]?.value
  );
  const [namevalue, setNameValue] = useState(
    AuthenticationSettingsData?.properties?.[0]?.value
  );
  const [clientIdvalue, setClientIdValue] = useState(
    AuthenticationSettingsData?.properties?.[2]?.value
  );
  const [providerMetaDatavalue, setProvierMetaDataValue] = useState(
    AuthenticationSettingsData?.properties?.[4]?.value
  );
  const [providerUrlValue, setProviderUrlValue] = useState(
    AuthenticationSettingsData?.properties?.[3]?.value
  );
  const [ischanged, setIsChanged] = useState(false);
  const [resetThis, setResetThis] = useState(0);
  const [sudoState, setSudoState] = useState(0);

  useEffect(() => {
    setProviderValue(
      AuthenticationSettingsData?.properties?.[1]?.value == null
        ? ""
        : AuthenticationSettingsData?.properties?.[1]?.value
    );
    setNameValue(
      AuthenticationSettingsData?.properties?.[0]?.value == null
        ? ""
        : AuthenticationSettingsData?.properties?.[0]?.value
    );
    setClientIdValue(
      AuthenticationSettingsData?.properties?.[2]?.value == null
        ? ""
        : AuthenticationSettingsData?.properties?.[2]?.value
    );
    setProvierMetaDataValue(
      AuthenticationSettingsData?.properties?.[4]?.value == null
        ? ""
        : AuthenticationSettingsData?.properties?.[4]?.value
    );
    setProviderUrlValue(
      AuthenticationSettingsData?.properties?.[3]?.value == null
        ? ""
        : AuthenticationSettingsData?.properties?.[3]?.value
    );
    setIsChanged(false)
  }, [AuthenticationSettingsData, resetThis]);

  useEffect(() => {
    if (
      providervalue == AuthenticationSettingsData?.properties?.[1]?.value &&
      namevalue == AuthenticationSettingsData?.properties?.[0]?.value &&
      clientIdvalue == AuthenticationSettingsData?.properties?.[2]?.value &&
      providerMetaDatavalue ==
        AuthenticationSettingsData?.properties?.[4]?.value &&
      providerUrlValue == AuthenticationSettingsData?.properties?.[3]?.value
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [sudoState]);

  const targets = [
    "edgeauthadminapi.registerIdentityProvider",
    "edgeauthadminapi.registerEdgeAdministrator",
  ];

  let payload = [
    {
      sectionName: AuthenticationSettingsData.sectionName,
      parentTypeId: AuthenticationSettingsData.parentTypeId,
      properties: [
        {
          value: {},
          targets: "",
        },
      ],
    },
  ];

  const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  let base64File = "";
  const uploadFile = async (event) => {
    const file = event.target.files[0];
    base64File = await convertBase64(file);
    let refactoredBase64 = base64File.split(";base64,");
    setrefactored_Base64File(refactoredBase64[1]);
  };

  const registerIdentityProvider = (values) => {
    if (refactored_base64File == "") {
      values.certificate = AuthenticationSettingsData?.properties?.[5]?.value;
    } else {
      values.certificate = refactored_base64File;
    }

    values.Name = namevalue;
    values.Provider = providervalue;
    values.ClientId = clientIdvalue;
    values.ProviderURL = providerUrlValue;
    values.ProviderMetadata = providerMetaDatavalue;
    payload[0].properties[0].value = values;
    payload[0].properties[0].targets = targets[0];
    console.log(values);
    calltheApi(payload, 0);

    AuthenticationSettingsData.properties[1].value = providervalue 
     AuthenticationSettingsData.properties[0].value =  namevalue 
     AuthenticationSettingsData.properties[2].value =  clientIdvalue 
      AuthenticationSettingsData.properties[4].value = providerMetaDatavalue 
     AuthenticationSettingsData.properties[3].value =  providerUrlValue 

     
  };

  let notiMsg;
  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
   
    let previosData =[];
    if(oldNotifications.newData.length>0){
      previosData = [...oldNotifications.newData];
    }
    
        previosData.push({
          notiId: `${notiId}`,
          text: `${notiText}`,
          time: data.time,
          data,
        });
     
      dispatch(
        setPageNotifications({
          payload: {
            isNew: true,
            data: oldNotifications.data,
            newData: previosData,
            // ...oldNotifications.newData,
          },
        })
      );


  };
  const calltheApi = (nativePayload, buttonNumber) => {
    setIsSavingIdentityProvider(true);
   

    let data = JSON.stringify(nativePayload);

    let updateAuthSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: updateAuthSettingsURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    Axios.request(config)
      .then((response) => {
        setIsChanged(false)
        if (response.data[0].status == "Error") {
          notiMsg = "Authentication settings failed, please try again";
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `AppRelatedUpdate_${CreateGuid()}`,
  
            {
              edgeId: edgeId,
              isEdge:true,
              modalKey:`AppRelatedUpdate_${CreateGuid()}`,
              edgeName:localStorage.getItem('edgeName'),
              appName:'',
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete:true,
              limit:3,
              currStateValue : 0,
              icon:'failed',
            }
          );

          message.error(notiMsg);
          setIsSavingIdentityProvider(false);

          return;
        }

        setIsSavingIdentityProvider(false);

        
        data=JSON.parse(data)
       
       
        AddEdgeSettingsNotification(
          `${data[0].sectionName} applied successfully`,
          `edgeSettingUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge:true,
            modalKey:`edgeSettingUpdate_${CreateGuid()}`,
            setting_type: data[0].parentTypeId.split("/")[1],
            settingSectionName: data[0].sectionName,
            edgeName:localStorage.getItem('edgeName'),
              appName:'',
            successMessage: `${data[0].sectionName} applied successfully`,
            time: generateTime(),
            isComplete:false,
            limit:3,
            currStateValue : 0,
            icon:'good',
          }
        );
      })
      .catch((error) => {
        console.log(error);

        setIsSavingIdentityProvider(false);

        notiMsg = "Authentication settings failed, please try again";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge:true,
            modalKey:`AppRelatedUpdate_${CreateGuid()}`,
            edgeName:localStorage.getItem('edgeName'),
              appName:'',
            successMessage: `${notiMsg}`,
            time: generateTime(),
            isComplete:true,
            limit:3,
            currStateValue : 0,
            icon:'failed',
          }
        );

        message.error(notiMsg);
      });
  };

  const trueFalse = () => {
    if (
      ischanged &&
      `${providervalue}` &&
      `${namevalue}` &&
      `${clientIdvalue}` &&
      `${providerMetaDatavalue}` &&
      `${providerUrlValue}`
    ) {
      dispatch(isUnsavedEdgeSettings({ payload: true }));
      return false;
    } else {
      dispatch(isUnsavedEdgeSettings({ payload: false }));
      return true;
    }
  };

 
  return (
    <div className="App authentication-settings-container">
      <div className="reverseproxy-header">
        {/* <h2 style={{ fontFamily: "ABBvoice-medium" }}>
          Authentication Settings
        </h2> */}
      </div>
      {/* <hr className="horizontal-line" /> */}

      <div className="create-edge-form">
        <h4
          style={{
            marginBottom: "1px",
          }}
          className="text_1"
        >
          Register default Identity Provider for Connect-On-Demand
          Authentication Service.
          
        </h4>
        
        <Form layout="vertical" onFinish={registerIdentityProvider}>
          <Form.Item
            label="Provider"
            // name="Provider"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={providervalue}
              onChange={(e) => {
                setProviderValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
            {/* <Input value={"fa"}/> */}
          </Form.Item>

          <Form.Item
            label="Name"
            // name="Name"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={namevalue}
              onChange={(e) => {
                setNameValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Client Id"
            // name="ClientId"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={clientIdvalue}
              onChange={(e) => {
                setClientIdValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Provider Metadata"
            // name="ProviderMetadata"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={providerMetaDatavalue}
              onChange={(e) => {
                setProvierMetaDataValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Provider URL"
            // name="ProviderURL"
            rules={[{ required: true }]}
            style={{ marginBottom: "10px" }}
            class="text_1"
          >
            <Input
              value={providerUrlValue}
              onChange={(e) => {
                setProviderUrlValue(e.target.value);
                setSudoState((prev) => prev + 1);
              }}
            />
          </Form.Item>
          <Form.Item
            // name="certificate"
            label="Upload Certificate"
            rules={[{ required: true }]}
            class="text_1"
            onChange={(e) => {
              uploadFile(e);
              setSudoState((prev) => prev + 1);
            }}
            style={{ marginBottom: "20px" }}
          >
            {/* <Upload name="logo" action="/upload.do" listType="picture"> */}
            {/* <Upload name="logo">
              <Button>Click to upload</Button>
            </Upload> */}
            <Input type="file" accept=".pem,.cert,.cer,.crt" />
          </Form.Item>
          <Form.Item style={{ marginBottom: "10px" }}>
            <div
              className="button-options"
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                // className="button-option"
                htmlType="submit"
                loading={isSavingIdentityProvider}
                // text="Register Identity Provider"
                style={{
                  background: trueFalse() ? General_color.disable : "#1f1f1f",
                  cursor: trueFalse() ? "not-allowed" : "pointer",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "5px 10px",
                  boxShadow: "unset",
                  fontWeight: "500",
                  border: "0px",
                  color: "white",
                  fontFamily: "ABBVoice",
                }}
                disabled={trueFalse()}
              >
                Apply
              </Button>
              <Button
                // type="primary"
                // className="button-option"

                // text="Cancel"
                style={{
                  borderRadius: "100px",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
                onClick={() => setResetThis((prev) => prev + 1)}
              >
                Reset
              </Button>
            </div>
          </Form.Item>
        </Form>

      </div>
    </div>
  );
}

export default Widget;
