import React, { useEffect, useState } from "react";
import Heading from "../SectionHeading/Heading";
import WarningIcons from "../WarningIcons/WarningIcons";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setEdgeStatus } from "../../reduxStore/actions";
import "./Widget.css";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Table } from "antd";

function Widget({ headingVisible }) {
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const apiReloader = useSelector((state) => state.setApiReloader);
  const dispatch = useDispatch();
  const [serviceData, setServiceData] = useState([]);
  const [refactoredPods, setRefactoredPods] = useState([]);
  const isSummaryVisible = useSelector((state) => state.setSummaryVisible);
  const [customHeight, setCustomHeight] = useState(0);
  const [isLoading, setisLoading] = useState(true);

  const adjustHeight = () => {
    let a = 0;
    let getMainContainer = document.getElementsByClassName("gridContainer");
    let getTheAboveApp = getMainContainer[4].getElementsByClassName("App");
    // console.log(getMainContainer)
    let appHeight = getTheAboveApp[0].clientHeight;
    if (isSummaryVisible == true) {
      a = 0;
    } else {
      a = 12;
    }
    let finalHeight = getMainContainer[1].clientHeight - appHeight - 10 + a;
    // console.log("value of inner height",finalHeight, appHeight)
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, [isSummaryVisible]);

  useEffect(() => {
    //Fetch Edge Details Status
    let statusEdgeDetailsURL = apiEndPoints.FETCH_STATUS_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );
    let Fetch_Status = {
      method: "get",
      maxBodyLength: Infinity,
      url: statusEdgeDetailsURL,
      headers: {
        // "Content-Type": "application/json",
      },
    };

    axios
      .request(Fetch_Status)
      .then((response) => {
        dispatch(
          setEdgeStatus({ payload: { data: response.data, isLoading: false } })
        );

        const PodData =
          response.data.edgeServiceMetricsSummary.edgeApplicationPodMetrics;

        // console.log(
        //   "This is a pod data ",
        //   PodData.filter((app) => app.isPlatform == true)
        // );

        const filteredPods = PodData?.filter((app) => app.isPlatform == true);
        setServiceData(filteredPods);
        setisLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  const checkStatus = (service) => {
    let StatusToReturn = "Running";

    service.map((pod, i) => {
      if (pod.statusPod == "Error") {
        StatusToReturn = "Error";
        return;
      }

      if (pod.statusPod == "Pending") {
        StatusToReturn = "Pending";
        return;
      }
    });

    return StatusToReturn;
  };
  useEffect(() => {
    if (serviceData?.length == 0) {
      return;
    }
    let correctedPods = [];
    serviceData?.map((pod, key) => {
      // console.log(pod);
      if (pod?.edgePodStatisticsList) {
        let servicesList = [];
        pod.edgePodStatisticsList.map((service, key) => {
          correctedPods.push({
            key: `service_${service.serviceNamePod}`,
            name: service.serviceNamePod,
            status: service.statusPod,
            cpu: service.cpuPod,
            memory: service.ramPod,
          });
        });
        // let thisStatus = checkStatus(pod.edgePodStatisticsList);
        // correctedPods.push({
        //   key: `pod_${pod.applicationName}`,
        //   name: pod.applicationName,
        //   status: thisStatus,
        //   cpu: pod.applicationCPU,
        //   memory: pod.applicationRAM,
        //   children: servicesList,
        // });
      }
      // } else {
      //   correctedPods.push({
      //     key: `pod_${pod.applicationName}`,
      //     name: pod.applicationName,
      //     status: "",
      //     cpu: pod.applicationCPU,
      //     memory: pod.applicationRAM,
      //   });
      // }
    });
    // console.log("correctedPods", correctedPods);
    setRefactoredPods(correctedPods);
  }, [serviceData]);

  const errors = [
    {
      name: "Cluster",
      details: "123 alerts total",
      icon: "critical",
    },
    {
      name: "Control Plane",
      details: "2 Components degraded",
      icon: "medium",
    },
    {
      name: "Storage",
      details: "Degraded",
      icon: "critical",
    },
    {
      name: "Hardware",
      details: "Degraded",
      icon: "medium",
    },
  ];

  const status = [
    {
      time: "2 mins ago",
      details: "The Api server has a 99th percentile latenct of 80 seconds",
      icon: "medium",
    },
    {
      time: "32 mins ago",
      details: "Disk device has rebooted",
      icon: "critical",
    },
    {
      time: "20 mins ago",
      details: "Cluster has overdue the CPU",
      icon: "medium",
    },
    {
      time: "12 mins ago",
      details: "33% target are down",
      icon: "critical",
    },
  ];

  const showHideModules = (id) => {
    let concatId = `canbehidden_${id}`;
    let parentRowId = `parentRow_${id}`;
    document.getElementById(`add_${id}`).classList.toggle("displaynone");
    document.getElementById(`remove_${id}`).classList.toggle("displaynone");
    let allparentTd = document
      .getElementById(parentRowId)
      .querySelectorAll("td");
    for (let i = 0; i < allparentTd.length; i++) {
      allparentTd[i].classList.toggle("hiddenParentUnderline");
    }

    let tt = document.getElementsByClassName(concatId);

    console.log(tt);
    for (let i = 0; i < tt.length; i++) {
      tt[i].classList.toggle("hiddenModules");
    }

    // document.getElementById(concatId).classList.toggle("hiddenModules");
  };

  const desciptiveData = (s, i) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "10px",
          padding: "10px",
        }}
        key={i}
      >
        <WarningIcons type={s.icon} />

        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <span style={{ fontSize: "11px" }}>{s.time}</span>
          <span style={{ fontSize: "15px", fontFamily: "ABBvoice-bold" }}>
            {s.details}
          </span>
        </div>
        <a
          target="_blank"
          href="https://observability.cod.abb.com"
          style={{ marginLeft: "auto", textDecoration: "none" }}
        >
          <div className="Hyper_link">
            <span style={{ fontWeight: "500" }}>View Details</span>
          </div>
        </a>
      </div>
    );
  };

  const Errors_div = (e, i) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "10px",
        }}
      >
        <WarningIcons type={e.icon} />
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}
          key={i}
        >
          <b style={{ fontSize: "15px", color: "#00308F" }}>{e.name}</b>
          <span style={{ fontSize: "12px" }}>{e.details}</span>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Pods",
      dataIndex: "name",
      // key: 'name',
      width: "25%",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      // key: 'age',
      width: "25%",
      ellipsis: true,
    },
    {
      title: "CPU %",
      dataIndex: "cpu",
      width: "25%",
      ellipsis: true,
      render: (cpu) => <span>{parseFloat(cpu).toFixed(2)}</span>,
    },
    {
      title: "Memory GB",
      dataIndex: "memory",
      width: "25%",
      ellipsis: true,
      render: (memory) => <span>{parseFloat(memory).toFixed(2)}</span>,
    },
  ];

  const generateTable = () => {
    return (
      <Table
        columns={columns}
        // rowSelection={{
        //   ...rowSelection,
        //   checkStrictly,
        // }}
        pagination={false}
        dataSource={refactoredPods}
        size="small"
        scroll={{
          y: customHeight - 45,
        }}
        expandable={{
          // expandedRowRender: record => (<p>{record.children[0].name}</p>),
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0) {
              // console.log("Recordddd: ", record);
              return expanded ? (
                <BiChevronDown
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <BiChevronRight
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => onExpand(record, e)}
                />
              );
            } else {
              return (
                <>
                  {/* <img
                    src="/pod.png"
                    alt="Kubernetes Pod Logo"
                    style={{
                      marginBottom: "-5px",
                      width: "20px",
                      marginLeft: "-5px",
                      marginRight: "5px",
                      alignItems: "center",
                    }}
                  /> */}
                  {/* // onClick={(e) => onExpand(record, e)} */}
                </>
              );
            }
          },
        }}
      />
    );
  };

  if (
    window.location.pathname === "/edgedetails" ||
    window.location.pathname === "/edgeResources"
  ) {
    return (
      <div className="App podStatusApp">
        {/* <div
          className="Heading_container"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "44.5%",
            borderBottom: "1px solid #e2e2e2",
            marginTop:'-10px'
          }}
        >
          {headingVisible && <Heading text="Status" />}
        </div> */}

        {/* <div
          style={{
            marginTop: "30px",
            padding: "10px",
            borderBottom: "1px solid #d2d2d2",
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            columnGap: "80px",
          }}
        >
          {cluster_ControlPlane_data.map((e, i) => Errors_div(e, i))}
        </div> */}

        <div
          style={{
            // height: `${
            //   isSummaryVisible ? "calc(52vh - 40vh)" : "calc(80vh - 42vh)"
            // }`,
            height: `${customHeight}px`,
            // overflow: "auto",
            transition: "0.3s",
            // background:'pink'
          }}
        >
          <div className="podsTable">
            {/* <table className="fixed_header">
              <thead
                style={{
                  position: "sticky",
                  top: "0",
                  background: "white",
                  boxShadow: "0px 0px 0px 1px #d2d2d2",
                  color:'unset'
                }}
              >
                <tr>
                  <th
                    style={{
                      
                      textAlign: "left",
                      width: "50%",
                      paddingLeft: "15px",
                    }}
                  >
                    Services/Pods
                  </th>
                  <th
                    style={{ textAlign: "left", width: "30%" }}
                  >
                    Status
                  </th>
                  <th
                    style={{ textAlign: "left", width: "25%" }}
                  >
                    CPU %
                  </th>
                  <th
                    style={{ textAlign: "left", width: "25%" }}
                  >
                    Memory GB
                  </th>
                </tr>
              </thead>
              <tbody style={{
                 height: `${customHeight-50}px`,
              }}>
           

                {serviceData?.map((service, i) => (
                  <>
                    <tr id={`parentRow_${i}`} key={`app_${i}`}>
                      <td
                        style={{
                          width:'50%',
                          paddingLeft: "15px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BiChevronRight
                          style={{ fontSize: "24px", cursor: "pointer" }}
                          onClick={() => showHideModules(i)}
                          id={`add_${i}`}
                        />

                        <BiChevronDown
                          style={{ fontSize: "24px", cursor: "pointer" }}
                          onClick={() => showHideModules(i)}
                          id={`remove_${i}`}
                          className="displaynone"
                        />
                        {service.applicationName}
                      </td>
                      <td style={{  width:'30%',}}>FE-Running</td>
                      <td style={{  width:'25%', }}>
                        {parseFloat(service.applicationCPU).toFixed(2)}
                      </td>
                      <td style={{  width:'25%', }}>
                        {parseFloat(service.applicationRAM).toFixed(2)}
                      </td>
                    </tr>
                    <>
                      {service.edgePodStatisticsList.map((data, j) => (
                        <tr
                          className={`canbehidden_${i} ${
                            service.edgePodStatisticsList.length - 1 === j
                              ? ""
                              : "noborderBelow"
                          } hiddenModules`}
                        >
                          <td style={{ }}>
                            <span style={{paddingLeft: "30px"}} className="modulecontainer">
                              {data.serviceNamePod}
                            </span>
                          </td>
                          <td style={{  }}>{data.statusPod}</td>
                          <td style={{  }}>
                            {parseFloat(data.cpuPod).toFixed(2)}
                          </td>
                          <td style={{}}>
                            {parseFloat(data.ramPod).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </>
                  </>
                ))}
              </tbody>
            </table> */}
            {generateTable()}
            {/* {!isLoading && serviceData.length == 0 && <NoDataComponent />} */}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <div
          className="Heading_container"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "44.5%",
            borderBottom: "1px solid #f0f0f0",
            marginTop: "-10px",
          }}
        >
          {headingVisible && <Heading text="Status" />}
        </div>

        <div
          style={{
            marginTop: "30px",
            padding: "10px",
            borderBottom: "1px solid #d2d2d2",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {errors.map((e, i) => Errors_div(e, i))}
        </div>

        <div style={{ marginTop: "20px" }}>
          {status.map((s, i) => desciptiveData(s, i))}
        </div>
      </div>
    );
  }
}

export default Widget;
