import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./Widget.css";
import Heading from "../SectionHeading/Heading";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import useSelection from "antd/es/table/hooks/useSelection";
import { useDispatch, useSelector } from "react-redux";
import { setSummaryVisible } from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import { General_color } from "../../constants/GeneralConstants";

function Widget() {
  const [showStat, setShowStat] = useState(true);
  const [pathname, setPathname] = useState("");
  const [platformNAME, setPlatformNAME] = useState("");
  const [appCount, setAppCount] = useState(0);

  const AllAvailableApps = useSelector((state) => state.setInstalledApps);
  const Alledges = useSelector((state) => state.setEdgesMetrics.data);
  const isLoading = useSelector((state) => state.setEdgesMetrics.isLoading);

  const dispatch = useDispatch();

  const [cpu_data, setCpuData] = useState([]);
  const [ram_data, setRamData] = useState([]);

  let green_cpu = 0;
  let red_cpu = 0;
  let yellow_cpu = 0;

  let totalGreeCpu = 0;
  let totalYellowCpu = 0;
  let totalRedCpu = 0;

  let totalRedRam = 0;
  let totalYellowRam = 0;
  let totalGreenRam = 0;

  let green_ram = 0;
  let red_ram = 0;
  let yellow_ram = 0;

  useEffect(() => {
    // console.log("top", cpu_data);
    setPathname(window.location.pathname);
    if (
      pathname.includes("/allapplications") ||
      pathname.includes("/alltenantapps")
    ) {
      setPlatformNAME("Application");
      setAppCount(AllAvailableApps?.length);
    } else {
      setPlatformNAME("Edge");
      setAppCount(Alledges?.length);
    }

    if (Alledges?.length > 0) {
      Alledges.map((e) => {
        if (e?.edgeMetrics.length == 0) {
          return;
        }
        if (
          Math.round(e?.edgeMetrics?.[0].data?.[0].v) >= 0 &&
          Math.round(e?.edgeMetrics?.[0].data?.[0].v) <= 50
        ) {
          // if (e.platformType == "unknown") {
          //   return;
          // }
          totalGreeCpu = totalGreeCpu + 1;
          green_cpu = green_cpu + Math.round(e?.edgeMetrics?.[0].data[0].v);
        }

        if (
          Math.round(e?.edgeMetrics?.[0].data[0].v) >= 51 &&
          Math.round(e?.edgeMetrics?.[0].data[0].v) <= 80
        ) {
          // if (e.platformType == "unknown") {
          //   return;
          // }
          totalYellowCpu = totalYellowCpu + 1;
          yellow_cpu = yellow_cpu + Math.round(e?.edgeMetrics?.[0].data[0].v);
        }

        if (
          Math.round(e?.edgeMetrics?.[0].data[0].v) >= 81 &&
          Math.round(e?.edgeMetrics?.[0].data[0].v) <= 100
        ) {
          // if (e.platformType == "unknown") {
          //   return;
          // }
          totalRedCpu = totalRedCpu + 1;
          red_cpu = red_cpu + Math.round(e?.edgeMetrics?.[0].data[0].v);
        }

        if (
          Math.round(e?.edgeMetrics?.[1].data[0].v) >= 0 &&
          Math.round(e?.edgeMetrics?.[1].data[0].v) <= 50
        ) {
          // if (e.platformType == "unknown") {
          //   return;
          // }
          totalGreenRam = totalGreenRam + 1;
          green_ram = green_ram + Math.round(e?.edgeMetrics?.[1].data[0].v);
        }

        if (
          Math.round(e?.edgeMetrics?.[1].data[0].v) >= 51 &&
          Math.round(e?.edgeMetrics?.[1].data[0].v) <= 80
        ) {
          // if (e.platformType == "unknown") {
          //   return;
          // }
          totalYellowRam = totalYellowRam + 1;
          yellow_ram = yellow_ram + Math.round(e?.edgeMetrics?.[1].data[0].v);
        }

        // if(Math.round(e?.edgeMetrics?.[1].data[0].v) >= 81 && Math.round(e?.edgeMetrics?.[1].data[0].v) <= 100){
        if (Math.round(e?.edgeMetrics?.[1].data[0].v) >= 81) {
          // if (e.platformType == "unknown") {
          //   return;
          // }
          totalRedRam = totalRedRam + 1;
          red_ram = red_ram + Math.round(e?.edgeMetrics?.[1].data[0].v);
        }
      });
      // setTotalCPU(cpu)
      setCpuData([
        {
          name: "green",
          value: green_cpu,
          number: totalGreeCpu,
          range: "0 - 50%",
        },
        {
          name: "yellow",
          value: yellow_cpu,
          number: totalYellowCpu,
          range: "51 - 80%",
        },
        {
          name: "red",
          value: red_cpu,
          number: totalRedCpu,
          range: "81 - 100%",
        },
      ]);

      setRamData([
        {
          name: "green",
          value: green_ram,
          number: totalGreenRam,
          range: "0 - 50%",
        },
        {
          name: "yellow",
          value: yellow_ram,
          number: totalYellowRam,
          range: "51 - 80%",
        },
        { name: "red", value: red_ram, number: totalRedRam, range: "> 80%" },
      ]);
    }
  }, [Alledges]);

  const COLORS = [
    General_color.success,
    General_color.warning,
    General_color.error,
  ];

  const generateStats = (statData) => {
    return statData.map((stat, index) => (
      <div className="eachStat" style={{ width: "auto", fontSize: "unset" }}>
        <span
          className="colorReceptor"
          style={{ backgroundColor: `${COLORS[index % COLORS.length]}` }}
        ></span>
        <span className="legendText">
          <span>{stat?.range}</span>
          {stat?.number == 1 && <span>{`${stat?.number} Edge`}</span>}
          {stat?.number > 1 && <span>{`${stat?.number} Edges`}</span>}
          {stat?.number == 0 && <span>{`None`}</span>}
        </span>
      </div>
    ));
  };

  // needle chart

  const generatePieChart = (statusData) => {
    return (
      <PieChart width={200} height={200}>
        <Pie
          data={statusData}
          cx={100}
          cy={100}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          // paddingAngle={0}
          dataKey="value"
        >
          {statusData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  };

  return (
    <div
      className="App"
      style={
        showStat
          ? {
              height: "215px",
              borderBottom: "1px solid #f0f0f0",
              padding: "0px",
            }
          : {
              height: "40px",
              borderBottom: "1px solid #f0f0f0",
              padding: "0px",
            }
      }
    >
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 10px",
          borderBottom: "1px solid #f0f0f0",
          padding: "10px",
          height: "20px",
        }}
      >
        <span>
          {" "}
          {/* <Heading text={`${platformNAME} Instance Summary (${appCount}/${appCount})`} /> */}
          <Heading text={`Summary`} /> {isLoading && <SpinnerLoader />}
        </span>
        <div
          onClick={() => {
            setShowStat(!showStat);
            dispatch(setSummaryVisible({ payload: !showStat }));
          }}
        >
          {showStat ? (
            <BiChevronUp
              style={{
                cursor: "pointer",
                marginTop: "10px",
              }}
              fontSize={"27px"}
            />
          ) : (
            <BiChevronDown
              style={{
                cursor: "pointer",
                marginTop: "10px",
              }}
              fontSize={"27px"}
            />
          )}
        </div>
      </section>
      {Alledges?.length > 0 && !isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "20px",
            marginTop: "8px",
          }}
        >
          <div
            className="leftPieChart"
            style={{ borderRight: "1px solid #f0f0f0" }}
          >
            {cpu_data?.[0]?.value == 0 &&
            cpu_data?.[1]?.value == 0 &&
            cpu_data?.[2]?.value == 0 ? (
              <div
                style={{
                  height: "155px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoDataComponent message="No CPU data at the moment" />
              </div>
            ) : (
              <div className="piechartContainer">
                <div
                  style={{
                    height: "160px",
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {/* <span className="chartHeading">  {platformNAME} Status</span> */}

                  <span className="chartHeading" style={{ fontSize: "15px" }}>
                    {" "}
                    Total <b>{Alledges?.length}</b> Edges{" "}
                  </span>
                  <div
                    style={{
                      position: "relative",
                      height: "200px",
                      width: "207px",
                      top: "-9px",
                      scale: "0.9",
                    }}
                  >
                    {generatePieChart(cpu_data)}
                    {/* <span
                   style={{
                     position: "absolute",
                     top: "42%",
                     left: "45%",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     flexDirection: "column",
                   }}
                 >
                   <span style={{ fontWeight: "500" }}>{appCount}</span>
                   <span style={{ fontSize: "12px" }}>total</span>
                 </span> */}
                  </div>
                </div>
                <div
                  style={{
                    width: "40%",
                  }}
                >
                  <span
                    style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}
                  >
                    {" "}
                    CPU Utilization
                  </span>
                  <div className="statWrapper">{generateStats(cpu_data)}</div>
                </div>
              </div>
            )}
          </div>

          <div className="rightPieChart">
            {ram_data?.[0]?.value == 0 &&
            ram_data?.[1]?.value == 0 &&
            ram_data?.[2]?.value == 0 ? (
              <div
                style={{
                  height: "155px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoDataComponent message="No RAM data at the moment" />
              </div>
            ) : (
              <div className="piechartContainer">
                <div
                  style={{
                    height: "160px",
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {/* <span className="chartHeading">{platformNAME} Status</span> */}
                  <span className="chartHeading" style={{ fontSize: "15px" }}>
                    {" "}
                    Total <b>{Alledges?.length}</b> Edges{" "}
                  </span>

                  <div
                    style={{
                      position: "relative",
                      height: "200px",
                      width: "207px",
                      top: "-9px",
                      scale: "0.9",
                    }}
                  >
                    {generatePieChart(ram_data)}
                    {/* <span
                   style={{
                     position: "absolute",
                     top: "42%",
                     left: "45%",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     flexDirection: "column",
                   }}
                 >
                   <span style={{ fontWeight: "500" }}>{appCount}</span>
                   <span style={{ fontSize: "12px" }}>total</span>
                 </span> */}
                  </div>
                </div>
                <div
                  style={{
                    width: "40%",
                  }}
                >
                  <span
                    style={{ fontFamily: "ABBVoice-medium", fontSize: "18px" }}
                  >
                    {" "}
                    RAM Utilization
                  </span>
                  <div className="statWrapper">{generateStats(ram_data)}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {Alledges?.length == 0 && !isLoading && <NoDataComponent />}
    </div>
  );
}

export default Widget;
