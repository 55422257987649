import React, { useDebugValue, useEffect } from 'react'
import Widget from './Widget'

function AppSettings({settingData, hide, containerid}) {

  return (
    <Widget settingData={settingData} hide={hide} containerid={containerid}/>
  )
}

export default AppSettings