import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar/Sidebar";
import DashboardComponents from "./pageComponents/DashboardComponents";
import { apiEndPoints } from "../constants/ApiEndPoints";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setApiReloader, setEdges, setEventLog } from "../reduxStore/actions";
import { message } from "antd";
import { GeneralConstants } from "../constants/GeneralConstants";

function Dashboard() {
  const dispatch = useDispatch();
  const AllEdges = useSelector((state) => state.setEdgesMetrics.data);
  // const access_token = localStorage.getItem("access_token");
  const [all_edges, setAllEdges] = useState([]);
  const [all_apps, setAllApps] = useState([]);
  const apiReloadTime = GeneralConstants.apiReloadTime;

  useEffect(() => {
    dispatch(setApiReloader({ payload: 0 }));
  }, []);

  const apiReloader = useSelector((state) => state.setApiReloader);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setApiReloader({ payload: apiReloader + 1 }));
    }, apiReloadTime);
  }, [apiReloader]);

  useEffect(() => {
    //   let fetch_all_edges_apps = {
    //     method: "get",
    //     maxBodyLength: Infinity,
    //     url: apiEndPoints.FETCH_ALL_EDGED_APP_METRICS,
    //     headers: {
    //       // Authorization: `Bearer ${access_token}`,
    //     },
    //   };

    //   axios
    //     .request(fetch_all_edges_apps)
    //     .then((response) => {
    //       dispatch(setEdges({ payload: response.data.edgeDetails }));
    //       setAllEdges(response.data.edgeDetails);
    //       setAllApps(response.data.applicationDetails);
    //       // if (response?.data?.edgeDetails?.length == 1) {
    //       //   message.info(
    //       //     "There is only one Edge, hence redirecting to edge details page"
    //       //   );
    //       //   setTimeout(() => {
    //       //     let redirect = window.location;
    //       //     let onlyEdge = response?.data?.edgeDetails[0];

    //       //     redirect.href = `/edgedetails?edgeName=${onlyEdge.name}&edgeId=${onlyEdge.edgeId}&platformType=${onlyEdge.platformType}&location=${onlyEdge.location}`;
    //       //   }, 5000);
    //       // }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    // fetch events
    let Fetch_Events = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_EVENTS,
      headers: {
        // "Content-Type": "application/json",
      },
      data: JSON.stringify({
        // filters: [
        //   {
        //     name: "edge_name",
        //     value: "edgeos.cod.abb.com",
        //   },
        // ],
        scope: "tenant",
        start: "",
        end: "",
        limit: 0,
      }),
    };

    axios
      .request(Fetch_Events)
      .then((response) => {
        dispatch(
          setEventLog({ payload: { data: response.data, isLoading: false } })
        );
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiReloader]);

  // let allApp = 0;
  // let totalInstalledApps = 0;
  // let totalMediumApps = 0;
  // let totalCritialApps = 0;
  // let unique =[];
  // let count_unique=0;
  // all_edges_app?.map((edge) => {
  //   // console.log(edge);
  //   if (edge?.applications) {
  //     allApp += edge?.applications?.length;

  //     edge?.applications?.map((app)=> {
  //       unique.push(app)
  //     });
  //     // count_unique = unique.length;
  //     edge?.applications?.map((app) => {
  //       if (app.status === "Installed") {
  //         totalInstalledApps += 1;
  //       }
  //       if (app.status === "medium") {
  //         totalMediumApps += 1;
  //       }
  //       if (app.status === "critial") {
  //         totalCritialApps += 1;
  //       }
  //     });
  //   }
  // });
  // let unique_apps = [...new Set(unique.map(item => item.name))];
  // count_unique = unique_apps.length;
  let allApp = 0;
  let apps_in_use = 0;
  allApp = all_apps?.appsAvailable;
  apps_in_use = all_apps?.appsInUse;

  const dashboardInventoryData = [
    {
      name: `${all_edges.length}`,
      route: "/alledges",
    },
    {
      name: `${allApp}`,
      details: `${apps_in_use} application(s) in use`,
      // warningstatus: [
      //   { icon: "Installed", value: `${totalInstalledApps}` },
      //   { icon: "medium", value: `${totalMediumApps}` },
      //   { icon: "critical", value: `${totalCritialApps}` },
      // ],
      route: "/alltenantapps",
    },
  ];

  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        <DashboardComponents
          inventoryData={dashboardInventoryData}
          inventoryLoading={all_edges.length == 0 ? true : false}
        />
      </div>
    </section>
  );
}

export default Dashboard;
