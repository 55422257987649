import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { General_color } from "../../constants/GeneralConstants";
import { BiChevronRight, BiRightArrow, BiRightIndent } from "react-icons/bi";
import { MdArrowRightAlt } from "react-icons/md";
import { RiArrowRightLine } from "react-icons/ri";

function SoftwareVersionsComponents() {
  const [showNewUpdate, setShowNewUpdate] = useState(false);
  const renderHeading = (txt) => {
    return (
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "100%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        {/* <Heading text="Tenant Details" /> {isLoading && <SpinnerLoader />} */}
        <Heading text={txt} />
      </div>
    );
  };

  const renderButton = (txt, type, show) => {
    return (
      <Button
        //   htmlType="submit"
        //   id="bc"
        //   type="primary"
        //   loading={isSaving}
        // icon={<PoweroffOutlined />}
        className="text_1"
        style={{
          background: type ? "#1f1f1f" : General_color.disable,
          color: type ? "#fff" : "#000",
          display: "flex",
          alignItems: "center",
          borderRadius: "31px",
          padding: "5px 15px",
          // boxShadow: " 0px 4px 6px -1px #d2d2d2",
          cursor: "pointer",
          fontWeight: "500",
          border: "0px",
          //   color: "white",
          // marginLeft: "auto",
          fontFamily: "ABBVoice",
        }}
        onClick={show}
        //   disabled={trueFalse()}
      >
        {txt}
      </Button>
    );
  };
  const renderOperatingSys = () => {
    return (
      <>
        {renderHeading("Operating System")}
        <div
          style={{
            fontFamily: "ABBVoice",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
              borderRight: "1px solid #f0f0f0",
              height: "20vh",
            }}
          >
            <b style={{ fontSize: "18px" }}>Edgenius OS</b>
            <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
          </div>

          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              borderRight: showNewUpdate ? "1px solid #f0f0f0" : "none",
              height: "20vh",
            }}
          >
            <b style={{ fontSize: "15px", display: "flex", gap: "20px" }}>
              {true ? (
                <span style={{color:General_color.disable}}>
                  Your edge is up to date
                </span>
              ) : (
                <>
                  <span>
                    Updates Available:{" "}
                    <i style={{ fontWeight: "100" }}>Version 0.10-rc.67</i>
                  </span>
                  <span
                    className="Hyper_link"
                    style={{ cursor: "pointer", display: "flex" }}
                    onClick={() => setShowNewUpdate(true)}
                  >
                    What's New{" "}
                    <span style={{ marginTop: "3px", fontSize: "20px" }}>
                      <BiChevronRight />{" "}
                    </span>
                  </span>
                </>
              )}
            </b>
            {false &&  renderButton("Apply Updates", true, () => ({}))}
          </div>
          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              overflow: "auto",
              maxHeight: "35vh",
              height: "45vh",
              //   paddingBottom:'10px'
            }}
          >
            {showNewUpdate && (
              <>
                <b style={{ fontSize: "15px" }}>What's New</b>
                <span style={{ fontSize: "15px" }}>Version 0.10-rc.67</span>
                <ul>
                  <li>
                    Enhanced User Interface: Enjoy a refreshed look and feel
                    with imporoved navigation and useability
                  </li>
                  <li>
                    Integration Uith Kubernetes: Seamless deploy and manage
                    containerized application with Kubernetes integration
                  </li>
                  <li>
                    Advanced Observability Tools: Gain deeper insights into
                    application performance with new observability tools
                  </li>
                  <li>
                    Performance Enhancement: Experience faster load times and
                    improved overall performance{" "}
                  </li>
                </ul>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {renderButton("Close", false, () => setShowNewUpdate(false))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderEdgeniusRuntime = () => {
    return (
      <>
        {renderHeading("Edgenius Runtime")}
        <div
          style={{
            fontFamily: "ABBVoice",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
              justifyContent: "center",
              borderRight: "1px solid #f0f0f0",
              height: "20vh",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <b style={{ fontSize: "18px" }}>Identity and Access Management</b>
              <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <b style={{ fontSize: "18px" }}>AppHosting Service</b>
              <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                justifyContent: "center",
              }}
            >
              <b style={{ fontSize: "18px" }}>AppHosting Service</b>
              <span style={{ fontSize: "15px" }}>0.10-rc.60</span>
            </span>
          </div>

          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
              borderRight: showNewUpdate ? "1px solid #f0f0f0" : "none",
              height: "20vh",
            }}
          >
            <b style={{ fontSize: "15px" }}>Latest Runtime Available</b>
            {renderButton("Apply Updates", true, () => ({}))}
          </div>
          <div
            style={{
              width: "32%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              overflow: "auto",
              maxHeight: "35vh",
              height: "45vh",
              //   paddingBottom:'10px'
            }}
          >
            {false && (
              <>
                <b style={{ fontSize: "15px" }}>What's New</b>
                <span style={{ fontSize: "15px" }}>Version 0.10-rc.67</span>
                <ul>
                  <li>
                    Enhanced User Interface: Enjoy a refreshed look and feel
                    with imporoved navigation and useability
                  </li>
                  <li>
                    Integration Uith Kubernetes: Seamless deploy and manage
                    containerized application with Kubernetes integration
                  </li>
                  <li>
                    Advanced Observability Tools: Gain deeper insights into
                    application performance with new observability tools
                  </li>
                  <li>
                    Performance Enhancement: Experience faster load times and
                    improved overall performance{" "}
                  </li>
                </ul>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {renderButton("Close", false, () => setShowNewUpdate(false))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
      <Col
        flex="1 1 525px"
        style={{
          columnGap: "10px",
          display: "grid",
          rowGap: "10px",
        }}
      >
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <div className="">{renderOperatingSys()}</div>
          </Col>
        </Row>
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <div className="">{renderEdgeniusRuntime()}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SoftwareVersionsComponents;
