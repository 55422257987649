import React, { useEffect, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";

import { IoIosAdd } from "react-icons/io";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import { useDispatch, useSelector } from "react-redux";

import {Table, Form} from "antd";

import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";

import { BiChevronDown, BiChevronRight } from "react-icons/bi";


function ApplicationsResourceMonitor({ apiLoaded }) {
  // const isSummaryVisible = useSelector(state=>state.setSummaryVisible)

  const isSummaryVisible = false;
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const dispatch = useDispatch();
  const [view, setView] = useState(0);
  const [customHeight, setCustomHeight] = useState(0);
  const original_AllApplications = useSelector(
    (state) => state.setInstalledApps
  );

  let [deleteAppForm] = Form.useForm();
  const [refactoredInstalledApp, setRefactoredInstalledApp] = useState([]);

  const [sudoState, setSudoState] = useState(0);

  const [showAddApplicationPannel, setShowAddApplicationPannel] =
    useState(true);

  const [open, setOpen] = useState({});

  const tableStat = (stat) => {
    return stat.map((d, i) => (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "5px",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", minWidth: "45px" }}>{d.value}%</span>
        <WarningIcons type={d.icon} value={d.value} />
      </div>
    ));
  };

  const showHideModules = (id) => {
    let concatId = `canbehidden_${id}`;
    let parentRowId = `parentRow_${id}`;
    document.getElementById(`add_${id}`).classList.toggle("displaynone");
    document.getElementById(`remove_${id}`).classList.toggle("displaynone");
    let allparentTd = document
      .getElementById(parentRowId)
      .querySelectorAll("td");
    for (let i = 0; i < allparentTd.length; i++) {
      allparentTd[i].classList.toggle("hiddenParentUnderline");
    }
    document.getElementById(concatId).classList.toggle("hiddenModules");
  };
  useEffect(() => {
    if (original_AllApplications.length > refactoredInstalledApp.length) {
      setView((prev) => prev + 1);
    }
    // console.log("riunned");
  });

  useEffect(() => {
    if (original_AllApplications.length == 0) {
      return;
    }

    let correctedInstalledApps = [];

    original_AllApplications.map((app, key) => {
      // console.log(app);
      if (app?.applicationModuleMetricsList?.length > 0) {
        let modulesList = [];
        app?.applicationModuleMetricsList?.map((module, moduleKey) => {
          // if(module.PodStatsList.length>0){
          modulesList.push({
            key: `${module?.displayName}_${moduleKey}_${app.model}_${key}`,
            name: `${
              module?.displayName == null ||
              module?.displayName == "" ||
              module?.displayName == undefined
                ? module?.name
                : module?.displayName
            }`,
            cpu: `${module?.cpu}`,
            memory: `${module?.memory}`,
            status: `${module?.status}`,
            version: module?.installedVersion,
          });
          // }
        });
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
          children: modulesList,
        });
      } else {
        correctedInstalledApps.push({
          originalObject: app,
          key: `${app.model}_app_${key}`,
          name: app?.displayName,
          type: app?.type,
          model: app?.model,
          status: `${app?.status == "Error" ? "Pending" : app?.status}`,
          version: app?.installedVersion,
          new_version: app?.version,
          cpu: app?.cpu,
          memory: app?.memory,
          isClickable: true,
        });
      }
      setRefactoredInstalledApp(correctedInstalledApps);
    });
  }, [original_AllApplications, view]);

  const columns = [
    {
      title: "Installed Application",
      dataIndex: "name",
      width: "20%",
      ellipsis: true,
      render: (text, record) => {
        if (record?.isClickable) {
          return (
            <a
              onClick={() => {
                const href = `/appDetails?edgeId=${edgeId}&settingData=${JSON.stringify(
                  record.originalObject
                )}&isAppInstalled=true`;

                window.location.href = `${href}`;
              }}
            >
              {text}
            </a>
          );
        } else {
          return <span>{text}</span>;
        }
      },
      sorter: (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      },
      // responsive: ['md'],
    },
    {
      title: "Status",
      dataIndex: "status",
      ellipsis: true,
      width: "10%",

      render: (status) =>
        status == undefined ? (
          "-"
        ) : (
          <span>
            {status == "Running" ? (
              <WarningIcons type="good" />
            ) : (
              <WarningIcons type="medium" />
            )}
          </span>
        ),
      // responsive: ['md'],
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      ellipsis: true,
      render: (cpu) =>
        cpu != undefined
          ? tableStat([
              {
                icon: "spiral",
                value: cpu,
              },
            ])
          : "-",
      width: "10%",
    },
    {
      title: "Memory",
      dataIndex: "memory",
      ellipsis: true,
      render: (memory) =>
        memory != undefined
          ? tableStat([
              {
                icon: "spiral",
                value: memory,
              },
            ])
          : "-",
      width: "10%",
    },
  ];

  const GenerateTable = (applications) => {
    return (
      <Table
        columns={columns}
        dataSource={applications}
        pagination={false}
        scroll={{
          y: customHeight - 45,
        }}
        size="small"
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            if (record?.children?.length > 0) {
              return expanded ? (
                <BiChevronDown
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              ) : (
                <BiChevronRight
                  style={{
                    fontSize: "20px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            } else {
              return (
                <BiChevronRight
                  style={{
                    fontSize: "0px",
                    marginBottom: "-5px",
                    width: "30px",
                    marginLeft: "-10px",
                  }}
                  onClick={(e) => {
                    onExpand(record, e);
                    e.stopPropagation();
                  }}
                />
              );
            }
          },
        }}
      />
    );
  };

  const adjustHeight = () => {
    let getMainContainer = document.getElementsByClassName("gridContainer");
    let finalHeight = getMainContainer[0].clientHeight - 100;
    setCustomHeight(finalHeight);
  };

  let rtime;
  let timeout = false;
  let delta = 100;

  window.addEventListener("resize", (event) => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      adjustHeight();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resizeend();
    }, 200);
  }, []);

  return (
    <div className="App">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
      >
        <style
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <Heading text="Applications" />
          {!apiLoaded && <SpinnerLoader />}
        </style>
        {
          // render_AddAppBtn()
        }
      </div>
      <div className="tableBox">
        <div
          className="ViewContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "5px",
            // height: `${
            //   isSummaryVisible ? "calc(52vh - 12vh)" : "calc(80vh - 12vh)"
            // }`,
            height: `${customHeight}px`,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              // diplay: "contents",
              width: "100%",
              backgroundColor: "#fff",
              height: "auto",
            }}
          >
            {apiLoaded && original_AllApplications.length == 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  flex: 1,
                }}
              >
                <div style={{ margin: "100px 0px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Welcome to Edgenius,{" "}
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "15px" }}>
                    It's a bit lonely out here on the edge without any
                    applications installed.{" "}
                  </span>
                  <br />
                  {/* <br /> */}

                  {/* <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      columnGap: "5px",
                    }}
                  > */}
                  {/* <span>Click</span> {render_AddAppBtn()} <span>to embark on your journey to a
                    powerful and personalized edge platform.</span> */}
                  {/* </span> */}
                  {/* <br /> */}
                  <span
                    style={{
                      fontSize: "15px",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <span>
                      {" "}
                      Browse through the list of all available applications and
                      use
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                        background: "#f3f3f3",
                        display: "flex",
                        borderRadius: "20px",
                        boxShadow: "0px 0px 0px 2px #d7d7d7",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <IoIosAdd style={{ fontSize: "20px" }} />
                      {/* Install Application */}
                    </span>
                    icon to add your favourite application to the Edge.
                  </span>
                </div>
              </div>
            )}

            {original_AllApplications.length > 0 &&
              apiLoaded &&
              GenerateTable(refactoredInstalledApp)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationsResourceMonitor;
